import React, {lazy} from 'react'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import koKR from 'antd/es/locale/ko_KR'
import './App.css'
import {RecoilRoot} from 'recoil'
import {ConfigProvider} from 'antd'
import {ApolloProvider} from '@apollo/client'
import {client} from './lib/apolloClient'

const DefaultRoutes = lazy(() => import('./routes'))

ConfigProvider.config({
  theme: {
    primaryColor: process.env.REACT_APP_THEME_COLOR
  }
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false
    }
  }
})

function App() {
  return (
    <RecoilRoot>
      <ApolloProvider client={client}>
        <ConfigProvider
          locale={koKR}
          theme={{
            token: {
              colorPrimary: process.env.REACT_APP_THEME_COLOR
            }
          }}
        >
          <QueryClientProvider client={queryClient}>
            <DefaultRoutes />
          </QueryClientProvider>
        </ConfigProvider>
      </ApolloProvider>
    </RecoilRoot>
  )
}

export default App
